import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { email_validator, password_validator } from '~config/validators';

const loginSchema = object().shape({
  email: email_validator,
  password: password_validator,
});

export default yupResolver(loginSchema);
