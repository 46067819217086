import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import {
  email_validator,
  string_validator,
  password_validator,
  confirm_email_validator,
} from '~config/validators';

const registerSchema = object().shape({
  firstName: string_validator('First name'),
  lastName: string_validator('Last name'),
  phoneNumber: string(),
  email: email_validator,
  confirmEmail: confirm_email_validator,
  password: password_validator,
});

export default yupResolver(registerSchema);
