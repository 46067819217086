import { Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useRouter } from 'next/router';
import Back from 'public/assets/icons/back.svg';
import ForgotPassword from 'public/assets/icons/forgot-password.svg';
import { resetPasswordDetail } from '~config/constants/texts';
import AuthResetPasswordForm from './components/AuthResetPasswordForm';
function ResetPassword({
  onLogin,
  ...rest
}: any) {
  const {
    push
  } = useRouter();
  const handleLogin = () => {
    if (onLogin) onLogin();else push('./login');
  };
  return <>
      <Stack sx={{
      border: '0px solid red',
      maxWidth: '450px',
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center'
    }} data-sentry-element="Stack" data-sentry-source-file="ResetPassword.tsx">
        <ForgotPassword height={120} width={120} data-sentry-element="ForgotPassword" data-sentry-source-file="ResetPassword.tsx" />

        <Typography sx={{
        fontSize: '35px !important',
        mt: 8
      }} variant="h3" paragraph data-sentry-element="Typography" data-sentry-source-file="ResetPassword.tsx">
          Forgot your password?
        </Typography>

        <Typography sx={{
        color: 'text.secondary',
        mb: 5,
        fontSize: '18px',
        width: '400px'
      }} data-sentry-element="Typography" data-sentry-source-file="ResetPassword.tsx">
          {resetPasswordDetail}
        </Typography>
        <AuthResetPasswordForm {...rest} data-sentry-element="AuthResetPasswordForm" data-sentry-source-file="ResetPassword.tsx" />
        <Box onClick={handleLogin} sx={{
        mt: 3,
        mx: 'auto',
        alignItems: 'center',
        display: 'inline-flex',
        cursor: 'pointer'
      }} data-sentry-element="Box" data-sentry-source-file="ResetPassword.tsx">
          <Back height={12} width={7} data-sentry-element="Back" data-sentry-source-file="ResetPassword.tsx" />
          <Typography sx={{
          ml: 1
        }} data-sentry-element="Typography" data-sentry-source-file="ResetPassword.tsx">
            Return to sign in
          </Typography>
        </Box>
      </Stack>
    </>;
}
export default ResetPassword;