import AuthModalLayout from '~layouts/auth-modal-layout';
import Register from '~screens/auth/Register';
import { IMAGES_PREFIX } from '~config/config-global';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useModalDialogs } from '~screens/shared/modal-dialogs/modal-dialogs-provider';
type RegisterModalType = {
  open: boolean;
  setOpen: any;
  setLoginModal: any;
  forbidClose?: boolean;
};
function RegisterModal({
  open,
  setOpen,
  forbidClose,
  setLoginModal
}: RegisterModalType) {
  const {
    triggerRegistrationOrLoginSuccessCallback
  } = useModalDialogs();
  const onLogin = () => {
    setOpen(false);
    setLoginModal(true);
  };
  return <AuthModalLayout open={open} setOpen={setOpen} forbidClose={forbidClose} leftImage={IMAGES_PREFIX + '/assets/images/register-modal.png'} data-sentry-element="AuthModalLayout" data-sentry-component="RegisterModal" data-sentry-source-file="RegisterModal.tsx">
      <IconButton sx={{
      position: 'absolute',
      top: 21,
      right: 16,
      cursor: 'pointer',
      zIndex: 10
    }} onClick={() => setOpen(false)} data-sentry-element="IconButton" data-sentry-source-file="RegisterModal.tsx">
        <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="RegisterModal.tsx" />
      </IconButton>

      <Register onLogin={onLogin} onRegisterSuccess={() => {
      setOpen(false);
      triggerRegistrationOrLoginSuccessCallback();
    }} data-sentry-element="Register" data-sentry-source-file="RegisterModal.tsx" />
    </AuthModalLayout>;
}
export default RegisterModal;