import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import { useRouter } from 'next/router';
import useResponsive from '~hooks/useResponsive';
import AuthLoginForm from './components/AuthLoginForm';
import SocialButtons from '~components/social-buttons';
import { useModalDialogs } from '~screens/shared/modal-dialogs/modal-dialogs-provider';
function Login({
  onRegister,
  ...rest
}: any) {
  const {
    palette: {
      text
    }
  }: any = useTheme();
  const {
    push
  } = useRouter();
  const {
    disableRegister
  } = useModalDialogs();
  const isMobile = useResponsive('down', 'md');
  const handleRegister = () => {
    if (onRegister) onRegister();else push('./signup');
  };
  return <>
      <Stack spacing={2} sx={{
      mb: '22px',
      border: '0px solid red',
      alignItems: isMobile ? 'center' : undefined
    }} data-sentry-element="Stack" data-sentry-source-file="Login.tsx">
        <Typography variant={isMobile ? 'h3' : 'h4'} data-sentry-element="Typography" data-sentry-source-file="Login.tsx">
          Log in to your account
        </Typography>
        {!disableRegister && <Typography onClick={handleRegister} sx={{
        cursor: 'pointer',
        display: 'flex',
        border: '0px solid red',
        maxWidth: '95%',
        width: '25em'
      }}>
            New user?{' '}
            <Typography component="span" sx={{
          pl: '1em',
          textDecoration: 'underline'
        }}>
              Register
            </Typography>
          </Typography>}
      </Stack>
      <AuthLoginForm {...rest} data-sentry-element="AuthLoginForm" data-sentry-source-file="Login.tsx" />
    </>;
}
export default Login;