import React, { useState } from 'react';
import { Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import LoginModal from '~components/login-modal';
import NewPasswordModal from '~components/new-password-modal';
import RegisterModal from '~components/register-modal';
import ResetPasswordModal from '~components/reset-password-modal';
import { useModalDialogs } from './modal-dialogs-provider';
const LoginModals = () => {
  const {
    user
  } = useSelector((state: any) => state.auth);
  const {
    isLoginDialogOpen,
    isRegisterDialogOpen,
    showLoginDialog,
    showRegisterDialog,
    hideDialogs
  } = useModalDialogs();
  const [newPasswordModal, setNewPasswordModal] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  if (user?.userDto?.role?.name !== 'Visitor' && user) return null;
  return <>
      <NewPasswordModal open={newPasswordModal} setOpen={setNewPasswordModal} setLoginModal={showLoginDialog} data-sentry-element="NewPasswordModal" data-sentry-source-file="login-modals.tsx" />
      <ResetPasswordModal open={resetPasswordModal} setLoginModal={showLoginDialog} setOpen={setResetPasswordModal} setNewPasswordModal={setNewPasswordModal} data-sentry-element="ResetPasswordModal" data-sentry-source-file="login-modals.tsx" />
      <RegisterModal open={isRegisterDialogOpen} setOpen={hideDialogs} setLoginModal={showLoginDialog} data-sentry-element="RegisterModal" data-sentry-source-file="login-modals.tsx" />
      <LoginModal open={isLoginDialogOpen} setOpen={hideDialogs} setRegisterModal={showRegisterDialog} setResetPasswordModal={setResetPasswordModal} data-sentry-element="LoginModal" data-sentry-source-file="login-modals.tsx" />
    </>;
};
export default LoginModals;

/*

import { useModalDialogs } from '../../shared/modal-dialogs/modal-dialogs-provider';
import LoginModals from '../../shared/modal-dialogs/login-modals.tsx';

function ParentComponent() {
    const { showLoginDialog, showRegisterDialog } = useModalDialogs();

    return (
        <div>
            <button onClick={showLoginDialog}>Show Login</button>
            <button onClick={showRegisterDialog}>Show Register</button>
            <LoginModals  />
        </div>
    );
}

export default ParentComponent;

*/