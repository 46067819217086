import AuthModalLayout from '~layouts/auth-modal-layout';
import Login from '~screens/auth/Login';
import { IMAGES_PREFIX } from '~config/config-global';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useModalDialogs } from '~screens/shared/modal-dialogs/modal-dialogs-provider';
type LoginModalType = {
  open: boolean;
  setOpen: any;
  setRegisterModal: any;
  setResetPasswordModal: any;
  forbidClose?: boolean;
};
function LoginModal({
  open,
  setOpen,
  setRegisterModal,
  setResetPasswordModal,
  forbidClose
}: LoginModalType) {
  const {
    triggerRegistrationOrLoginSuccessCallback
  } = useModalDialogs();
  const onRegister = () => {
    setOpen(false);
    setRegisterModal(true);
  };
  const onResetPassword = () => {
    setOpen(false);
    setResetPasswordModal(true);
  };
  return <AuthModalLayout forbidClose={forbidClose} open={open} setOpen={setOpen} leftImage={IMAGES_PREFIX + '/assets/images/signin-modal.png'} data-sentry-element="AuthModalLayout" data-sentry-component="LoginModal" data-sentry-source-file="LoginModal.tsx">
      <IconButton sx={{
      position: 'absolute',
      top: 21,
      right: 16,
      cursor: 'pointer',
      zIndex: 10
    }} onClick={() => setOpen(false)} data-sentry-element="IconButton" data-sentry-source-file="LoginModal.tsx">
        <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="LoginModal.tsx" />
      </IconButton>

      <Login onRegister={onRegister} onLoginSuccess={() => {
      setOpen(false);
      triggerRegistrationOrLoginSuccessCallback();
    }} onResetPassword={onResetPassword} data-sentry-element="Login" data-sentry-source-file="LoginModal.tsx" />
    </AuthModalLayout>;
}
export default LoginModal;